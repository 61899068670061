.guide {
    background: khaki;
    font-size: 0.8rem;
    position: absolute;
    padding: 0 7px;
    border-radius: 5px;
}

#guide-step1 {    
    top: 7%;
    left: -20%;
}

#guide-step2 {
    top: 16%;
    left: -25%;
}

#guide-step3 {
    top: 64%;
    left: 60.5%;
}