.admin-modal {
    height: 155px !important;
    width: 385px !important;
    background: lightgray !important;
}

.admin-modal .header {
    height: 30px !important;
    padding: 8px !important;
    border: none !important;
    font-size: 0.85rem !important;
    color: #17323b !important;
    font-weight: bold !important;
    background: lightgray !important;
}

.admin-modal .content {
    background: white !important;
    width: 94% !important;
    height: 74% !important;
    margin: auto !important;
}

.admin-modal .admin-label {
    margin: 0 !important;
    text-align: center;
    font-size: 0.72rem !important;
}

.admin-modal .admin-input-field {
    display: flex !important;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    margin-top: 10px !important;
    margin-bottom: 10px !important;

}

.admin-modal .admin-input-field span {
    font-size: 0.72rem !important;
}

.admin-modal .admin-input-field .input {
    display: inline-block !important;
    width: 250px !important;
}

.admin-modal .ui.fluid.input>input {
    width: 250px !important;
}

.admin-modal .actions {
    background: lightgray !important;
    border: none !important;
    padding: 5px !important;
}

.admin-modal .login-btn {
    height: 20px !important;
    width: 55px !important;
    padding: 1px !important;
    font-size: 0.72rem !important;
    font-weight: bold !important;
    float: right;
}

.admin-modal .error {
    color: red;
    font-size: 0.72rem !important;
}