/* CHANGED UI tab */
.ui.attached.tabular.menu {
  height: 2em;
  min-height: 0;
}

.ui.tabular.menu .active.item {
  padding: 0.5em 0.4em !important;
}
/* CHANGED UI tab */

.imaging-field .input input {
  width: 100% !important;
}

.injector-input{
  padding: 5px 5px 0 !important;
}

.injector-input input {
  height: 75% !important;
}

.thickness .actions {
  border: none !important;
}

.thickness-params-btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 116px !important;
}

.thickness-params-btns .scan-param-item{
  width: 17%;
}

.th-parameter-ctrl {
  width: 18% !important;
  margin: 5px !important;
  height: 3.5rem !important;  
  text-align: center;
  padding: 0 !important;
}

.padding-top {
  margin-top: 6px !important;
}

.imaging-form .fields {
  margin-bottom: 2px !important;
}

.custom-image-input {
  margin: auto;
  display: flex;
  align-items: center;
}

.custom-image-input label {
  text-align: right;
  font-size: 0.8rem !important;
  margin: 0 !important;  
  font-weight: 100 !important;   
  line-height: 9px;     
}

.custom-image-input .input {
  width: 100% !important;
  /* margin-left: 5px !important; */
}

.custom-image-input .dropdown {
  width: 100% !important;
  font-size: 0.8em !important;
  background: linear-gradient(to top, #cccccc 1%, #ffffff 100%) !important;
  font-weight: 900 !important;
}

.custom-image-input .dropdown .text {
  font-size: 0.75rem !important;
  font-weight: bold !important;
  color: #17323b !important;
}

.custom-image-input .icon {
  top: 0 !important;
  font-size: 1em !important;
  margin: auto !important;
}

#injector .header {
  margin-bottom: 3px !important;
  margin-left: 5px !important;
}

#injector table {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  margin-left: 3px !important;
  width: 99% !important;
}

#injector th, #injector .t-title  {
  line-height: 11px;  
  font-size: 0.8rem;
  padding: 4px 0 !important;
}

#injector .input, #injector .dropdown {
  height: 2.2rem !important;
  font-size: 0.8em !important;
}

.contrast-oral input{
  height: 3.5rem !important;
}

.scan-delay label, .peak-psi label {
  color: white;
  font-size: 0.8rem;
  margin: 0 !important;
}

.scan-delay input, .peak-psi input {
  height: 85%;
  text-align: center !important;
}

.text-align-center input{
  display: flex;
  justify-content: center !important; 
  text-align: center !important;
}

.text-align-right input{
  display: flex;
  justify-content: right !important; 
  text-align: right !important;
}


.contrast-oral-item {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.contrast-oral-items {
  display: flex;
  flex-direction: row;
  padding: 0 0 0 3px;
  margin-bottom: 2%;
}

.contrast-oral-item.margin-top, .contrast-oral-item.margin-top  {
  margin-top: 5% !important;
}

.contrast-oral-item .contrast-oral-btn, .contrast-oral-items .contrast-oral-btn {
  padding: 1rem 0.5em !important;
  width: 40% !important;
  height: 3.5rem !important;
}

.contrast-oral-item .contrast-oral-data, .contrast-oral-items .contrast-oral-data {
  min-width: 40%;
  margin-left: 3%;
  background: white;
  display: flex;
  height: 49px;
  width: 50px;
  overflow: auto;
  font-size: 10px;
  line-height: 11px;
  text-align: center;
}

.contrast-oral-data span{
  margin: auto;
}

#injector input {
  cursor: pointer;
}

.menu .item {
  font-size: 0.85rem !important;
  font-weight: 700 !important;
}

.ui.tab {
  padding: 0 !important;
}

/* ThicknessParameters */
.change-tk-values {
  display: flex;
  justify-content: flex-end;
  padding: 6px 0 12px 0!important;
}

.scan-param-modal.thickness {
  width: 40% !important;
}

.ctrls-label{
  font-weight: 700;
  margin: 5px !important;
}

.tk-ctrls {
  display: flex !important;
  flex-wrap: wrap;
  padding: 3px 0 0 3% !important;
}

.tk-ctrl-btn {
  flex: 0 0 20.8%;
  margin: 3px !important;
}

.tk-ctrl-btn.active {
  background: #76eafc !important;
}
/* ThicknessParameters */

.imaging-form .row {
  padding: 6px 0 0 0px !important;
}

.imaging-form .ui.grid {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}

.imaging-form .column {
  font-size: 0.8rem !important;
  line-height: 1rem !important;
  padding: 0 !important;
}

.imaging-form .column.label {
  font-size: 0.7rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end;
  margin-right: 5px !important;
  text-align: right;
}

.imaging-form .input input {
  padding: 5px !important;
}

.imaging-form .column .dropdown {
  min-width: 0em !important;
}

.imaging-form .ui.dropdown>.dropdown.icon {
  font-size: 1.5em !important;
}

.imaging-form .column .custom-image-input .icon {
  width: 0 !important;
  right: 0px !important;
  padding-top: 5px !important;
  padding-bottom: 0px !important;
}

.imaging-form .grid {
  margin-top: 0 !important;
}

.imaging-form .contrast-oral-item {
  margin-top: 7px !important;
}

.imaging-form .select-min {
  padding: 2px !important;
  min-height: 0 !important;
}

.imaging-form  .select-min .dropdown.icon {
  top: 0 !important;
  margin: auto !important;
}

.ui.tab.active, .ui.tab.open {
  padding-top: 5px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
}

.ui.tab.active.injector-tab, .ui.tab.open.injector-tab {
  padding-left: unset !important;
  padding-right: unset !important;
  padding-bottom: unset !important;
  padding-top: 7px !important;
}

.injector-tab thead th {
  padding: 2px !important;
  color: white !important;
  background: #798F9A !important;
  height: 31px !important;
}

.ui.tab.active.injector-tab .grid {
  margin: auto;
}

.ui.tab.active.injector-tab .grid .column {
  padding-bottom: 2px !important; 
  padding-top: 0 !important; 
  padding-left: 0.4rem !important; 
}

.contrast-oral-item {
  border-top: 1px solid white;
}

.scan-param-modal.thickness.one-val-change, 
.scan-param-modal-th.thickness.one-val-change {
  margin: auto !important;
  width: 350px !important;  
  background: #7c868c !important;
}

.scan-param-modal.thickness.one-val-change .header, 
.scan-param-modal-th.thickness.one-val-change .header {
  border: none !important;
}

.scan-param-modal.thickness.one-val-change .icon, 
.scan-param-modal-th.thickness.one-val-change .icon {
  padding: 5px !important;
}

.scan-param-modal.thickness.one-val-change .content, 
.scan-param-modal-th.thickness.one-val-change .content {
  width: 95% !important;
  margin: auto !important;
  padding: 0 !important;
}

.scan-param-modal.thickness.one-val-change .content .container, 
.scan-param-modal-th.thickness.one-val-change .content .container {
  width: 100% !important;
}

.scan-param-modal.thickness.one-val-change .footer, 
.scan-param-modal-th.thickness.thickness.one-val-change .footer {
  border: none !important;
}

.scan-param-modal.thickness.one-val-change button {
  height: 20px !important;
  width: 70px !important;
  padding: 1px 0px !important;  
  font-weight: 900 !important;
  margin-right: 4%;
}

.scan-param-modal-th.thickness.one-val-change button {
  height: 20px !important;
  width: 57px !important;
  padding: 1px 0px !important;  
  font-weight: 900 !important;
  font-size: 0.8rem !important;
}

.modal-label {
  font-weight: 900 !important;
  font-size: 0.8em !important;
  margin-left: 10px !important;
}

.modal-params-ctrls {
  display: flex !important;
  justify-content: flex-end !important;
  padding-bottom: 10px !important;
  padding-right: 5% !important;
}

.modal-params-ctrls .button {
  width: 18.1% !important;
}

.thickness.scan-param-modal-th.one-val-change.slice-gap {
  width: 280px !important;
}
.thickness.one-val-change.slice-gap .content .modal-label {
  display: none;
}

.thickness.one-val-change.slice-gap .content .tk-ctrls {
  padding-top: 10px !important; 
}

.ui.page.dimmer { 
  background-color: rgba(255, 255, 255, 0.4) !important; 
}

.scan-param-modal.thickness .ui.container, 
.scan-param-modal-th.thickness .ui.container {
  width: 95% !important;
}

.min-btn.reconstruction {
  width: 58% !important;
}

.min-btn.tk-btn {
  padding: 5px 15px !important;
}

.injector-modal-param .modal-params-ctrls .button {
  width: auto !important;
}

.padding-bottom {
  padding-bottom: 17px;
}
.thickness.modal.mini .header {
  font-size: 0.9rem !important;
}

.scan-tabs-section .segment {
  height: 224.78px !important;
}