body {
  background: #93a4ab !important;
  font-size: 1rem !important;
}

h5 {
  font-size: 0.85rem !important;
}

h4, h5 {
  margin-top: 0 !important;
  margin-bottom: 10px !important;
}

h6 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

h3.header, h4.header, h5.header {
  color: white !important;
}

label {
  text-align: left;
  display: block;
  margin: 12px 0 .28571429rem 0;
  font-size: .92857143em;
  font-weight: 700;
  text-transform: none;
}

.ui.modal .close.icon {
  font-size: 0.9rem !important;
  top: 0 !important;
  right: 0 !important;
  color: #000000 !important;
}

.segment.tab, .tab table {
  background-color: #8e9faa !important;
}

.ui.container {
  width: 90% !important;
}

.wide.column.top-position {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

button.min-btn {
  padding: 4px !important;
  font-size: 0.75rem !important;
}

.home-page input, .modal input {
  padding: 2px !important;
}

.register-form 

/* SECTION padding */
.ui.celled.grid>.column:not(.row), .ui.celled.grid>.row>.column {
  padding: 0.5em !important;
}
/* SECTION padding */

/* MODAL */
.modal.mini {
  width: 30% !important;
}

.modal.mini .header {
  font-size: .8rem !important;
  color: #17323b !important;
}

.modal.mini .content {
  padding-left: 2%;
  padding-right: 2%;
}

.modal.mini .button {
  padding: 1px 10px !important;
}
/* MODAL */

.home-page .ui.dropdown>.dropdown.icon {
  right: 5px !important;
  font-size: 1.2em !important;
  width: auto !important;
}

.home-page .ui.dropdown .menu .item.active {
  background-color: skyblue !important;
}

.home-page .ui.dropdown .menu .item {
  border: none !important;
  font-weight: 400 !important;
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.home-page .ui.dropdown .menu .item:hover {
  background-color: #b2e1ff !important;
}

.home-page .ui.fluid.dropdown {
  background: linear-gradient(to top, #cccccc 1%, #ffffff 100%) !important;
  font-weight: bold !important;
  color: #17323b !important;
}

.ui.selection.visible.dropdown>.text:not(.default) {
  font-weight: bold !important;
}

.ui.tabular.menu .active.item {
  background: #93a4ab !important;
}

.ui.tabular.menu .item {
  border-right: 1px solid lightgrey;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background: rgba(254,254,254,1);
  background: rgba(254,254,254,1);
  background: -moz-linear-gradient(top, rgba(254,254,254,1) 0%, rgba(226,226,226,1) 0%, rgba(142,154,160,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(254,254,254,1)), color-stop(0%, rgba(226,226,226,1)), color-stop(100%, rgba(142,154,160,1)));
  background: -webkit-linear-gradient(top, rgba(254,254,254,1) 0%, rgba(226,226,226,1) 0%, rgba(142,154,160,1) 100%);
  background: -o-linear-gradient(top, rgba(254,254,254,1) 0%, rgba(226,226,226,1) 0%, rgba(142,154,160,1) 100%);
  background: -ms-linear-gradient(top, rgba(254,254,254,1) 0%, rgba(226,226,226,1) 0%, rgba(142,154,160,1) 100%);
  background: linear-gradient(to bottom, rgba(254,254,254,1) 0%, rgba(226,226,226,1) 0%, rgba(142,154,160,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#8e9aa0', GradientType=0 );
}

.column .top-position {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

/* STOP DRAGGING IMAGE */
.carousel img {
  pointer-events: none;
}

button {
  color: #0B333C !important;
  font-weight: 900 !important;
}

.blurring.dimmed.dimmable>:not(.dimmer) {
  filter: blur(1px) !important;
  -webkit-filter: blur(1px) !important;
}

.carousel .ui.button:focus {
  background-color: #e0e1e2 !important;
}
.carousel .ui.button:hover {
  background-color: white !important;
}

.carousel__slider {
  background: #000;
}

/* input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}

input[type=number]::-webkit-outer-spin-button, 
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button !important;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
} */