.form-grid {
  padding-top: 2% !important;
  overflow-y: scroll;
  height: 100vh;
  margin-right: 0 !important;
}

.form-grid-column {
  max-width: 450px;
}

.sign-up-link {
  margin-bottom: 0 !important;
  margin-top: 2px !important;
}

.restore-link {
  margin-top: 2px !important;
}