#scan_params .header {
  margin: 1% !important;
}

.parameter-card-content {
  height: 3.3rem;
  text-align: center;
  padding: 0 2px !important;
  font-size: 0.75em !important;
}

.scan-params-ctrls {
  display: flex;
}

.parameter-ctrl {
  width: 10%;
  margin: 4px !important;
  height: 3.5rem !important;  
  text-align: center;
  padding: 0 !important;
  max-height: 600px;
}

.scan-param {
  width: 100%;
  height: 3.5rem;
  display: flex;
  margin-top: 10px;  
  text-align: center;
  align-items: center;  
  background: white;
  justify-content: center;
  font-size: 0.7rem !important;
}

.scan-params-btns {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.9rem;
  flex-direction: row;
  max-height: 115px;
}

.scan-param-item {
  width: 10%;
  margin: 5px;
}

.new-ptcl {
  margin-left: 8% !important;
}

.prev-series {
  margin-left: 3% !important;
}

/* MODAL */

.modal.mini.scan-param-modal {
  width: 20.5% !important;
}

.modal.mini.scan-param-modal-th{
  width: 350px !important;
}

.scan-param-modal .header, .scan-param-modal .actions,
.scan-param-modal-th .header, .scan-param-modal-th .actions{
  background: #8294A6 !important;
}

.scan-param-modal .header,
.scan-param-modal-th .header {
  padding: 0.25rem 1.5rem !important;
}

.scan-param-modal .actions,
.scan-param-modal-th .actions {
  padding: 0.5rem 1.5rem !important;
}

.scan-param-modal .content,
.scan-param-modal-th .content {
  background: #96A8B7 !important;
}

.modal.mini.scan-param-modal,
.modal.mini.scan-param-modal-th {
  background: #8294A6 !important;
}

.modal.mini.scan-param-modal .content,
.modal.mini.scan-param-modal-th .content {
  padding-bottom: 4px !important;
  width: 95% !important;
  margin: auto !important;
}

.modal.mini.scan-param-modal .content .dropdown,
.modal.mini.scan-param-modal-th .content .dropdown {
  min-height: 25px !important;
  padding: 2% !important;
}

.modal.mini.scan-param-modal .content .dropdown .icon,
.modal.mini.scan-param-modal-th .content .dropdown .icon{
  padding-right: 15px !important;
}

.modal.mini.scan-param-modal .modal-params-ctrls, 
.modal.mini.scan-param-modal-th .modal-params-ctrls{
  margin-top: 5px !important;
  padding-bottom: 3px !important;
}

.modal.mini.scan-param-modal .icon, .modal.mini.scan-param-modal-th .icon {
  padding: 5px !important;
  width: 20px;
}

.modal.mini.scan-param-modal .header, .modal.mini.scan-param-modal .actions {
  border: none !important;
}

.scanParam-modal-param .modal-params-ctrls {
  padding-right: 0 !important;
}

.scanParam-modal-param .modal-params-ctrls .button {
  width: 60px !important;
  height: 22px !important;
  font-size: 0.75rem !important;
}