.notification-modal {
    width: max-content !important;
    background: #93a4ab !important;
    min-width: 120px !important;
}

.notification-modal .header {
    padding: 0.8rem !important;
    border: none !important;
    background: #93a4ab !important;
}

.notification-modal .content {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0.5rem 0.1rem !important;
    width: 94% !important;
    margin: auto;
    background: lightgray !important;
    font-size: 0.75rem !important;
    min-width: 110px !important;
}

.notification-modal .actions {
    padding: 0.5rem !important;
    border: none !important;
    background: #93a4ab !important;
}

.notification-modal .content .button {
    height: 20px !important;
    width: 70px !important;
    min-height: 0 !important;
    padding: 4px;
    font-size: 0.7rem;
    font-weight: 900;
    border: 2px solid gray !important;
    margin-top: 14px !important;
}

.notification-modal .content-action {
    display: flex;
    justify-content: center;
}