.restore-page {
    /* padding-top: 10rem; */
}

.restore-block {
    width: 400px;
    margin: 0 auto;
    text-align: center;
    background: white;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.restore-block > h3 {
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

.restore-block > p {
    color: red;
    font-size: 12px;
    padding-top: 5px;
}

.input-restore {
    width: 300px;
    height: 40px !important;
    font-size: 14px !important;
}

.restore-submit { 
    display: block !important;
    width: 100px;
    margin: 14px auto 0 auto !important;
    color: white !important;
}

.restore-page .message {
    width: 400px !important;
    margin: 5px auto 0 auto !important;
    padding: 5px 10px !important;
    font-size: 12px !important;
}

.restore-page .message .header {
    margin-bottom: 10px !important;
}