.logoImage {
    width: 50%;
    margin: auto;
}

.nav-bar-images {
    width: 85%;
    margin: 10px auto;
}

.navBar-componetn .ui.button {
    padding: 5px 16px !important;
}

.selected-protocol {
    margin-bottom: 5px !important;
}

.selected-protocol label {
    font-weight: 100 !important;
}

.nav-ctrls {
    display: flex;
    justify-content: space-evenly;
}