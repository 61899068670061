#canvas {
    width: 100%;
    height: 100%;
}

.plan-slices {
    display: contents;
    width: 60% !important;
}

.padding-ctrl {
    padding: 4px !important;
}

.select-padding-ctrl {
    padding: 5px 0px !important;
    font-size: 0.8em !important;
    width: 55%;
    height: 100%;
}

.plan-slices-dashboard {
    z-index: 2;
    height: auto !important;
    position: absolute;
    width: -webkit-fill-available;
    height: 71% !important;
}

#autoView {
    height: 100%;
    width: 100%;
}
.autoview-container {
  height: 100% !important;
  /* width: 230px !important; */
}

.full-height {
  display: inline;
}

.empty-image {
  width: 100%;
  height: 215px;
  background-color: black;
  padding-bottom: 10px;
}
.carousel-image {
  width: 100%;
  height: 215px;
}
.carousel-image img {
  width: 100%;
  height: 100%;
}

button.btn-prev,
button.btn-next {
  font-size: 1.2em !important;
  padding: 0 !important;
  width: 10% !important;
}

.autoview-ctrls {
  display: flex;
  margin-top: 8px !important;
  justify-content: center;
  height: 8%;
}

.scan-series-select {
  display: flex !important;
  align-items: left;
  padding: 5px 5px !important;
  min-height: 1em !important;
  min-width: 20% !important;
}

.scan-series-select .icon {
  top: 0 !important;
  right: 0 !important;
  padding-top: 35% !important;
}

.search-bth {
  padding: 2px !important;
  /* margin-top: 5px !important; */
  position: absolute !important;
  right: 0 !important;
  top: 6% !important;
}

.auto-view-modal .content {
  padding: 0.5rem !important;
}

.auto-view-modal .autoview-ctrls {
  height: 26px;
  display: flex !important;
  justify-content: center !important;
}

.auto-view-modal button.btn-prev,
.auto-view-modal button.btn-next {
  width: 5% !important;
}

.auto-view-modal .scan-series-select .icon {
  padding-top: 12% !important;
}

.black-image {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 210;
}

.autoview-container .carousel__slider {
  height: auto !important;
}

.carousel {
  -webkit-user-select: none;
  user-select: none;
}

.auto-view-modal {
  background-color: lightgray !important;
}

.auto-view-modal .header {
  padding: 0.5rem 1rem !important;
  font-size: 0.8rem !important;
  border: none !important;
  background-color: lightgray !important;
}

.auto-view-modal .carousel,
.auto-view-modal .carousel .carousel__slider,
.auto-view-modal .carousel .carousel__slide {
  height: -webkit-fill-available;
  height: 100%;
}

.auto-view-modal .content {
  width: 98% !important;
  margin: auto !important;
  height: 94.5% !important;
  padding-bottom: 36px !important;
  background-color: white !important;
}

.auto-view-modal img {
  width: 65% !important;
  margin: auto !important;
}

.auto-view-modal .dashboard {
  position: absolute;
  width: 100%;
  height: 86%;
  margin: auto;
  top: 6%;
}

.auto-view-modal .ui.selection.dropdown {
  background: linear-gradient(to top, #cccccc 1%, #ffffff 100%) !important;
  font-weight: bold !important;
  color: #17323b !important;
}

.auto-view-modal .ui.selection.dropdown .menu > .item {
  border: none;
}

.auto-view-modal .ui.dropdown .menu .item.active {
  background-color: skyblue !important;
}

.auto-view-modal .ui.dropdown .menu .item:hover {
  background-color: #b2e1ff !important;
}

.auto-view-modal .scan-series-select {
  display: flex !important;
  align-items: center;
  padding: 5px !important;
  min-height: 1em !important;
  min-width: 50px !important;
}

.auto-view-modal button.btn-prev,
.auto-view-modal button.btn-next {
  width: 26px !important;
}

.auto-view-modal .scan-series-select .icon {
  padding-top: 16px !important;
}

#autoview-modal {
  height: 600px;
  width: 900px;
  padding-bottom: 10px !important;
}

.scan-progress {
  margin-top: 4px !important;
}

.scan-progress-container {
  margin-top: -25px;
  padding-top: 15px !important;
  padding-bottom: 10px !important;
}

.scan-progress-container-ctrl {
  display: flex !important;
  align-items: center;
  margin-top: -25px;
  padding-top: 15px !important;
}

.progress-label {
  font-size: 0.8rem !important;
  font-weight: 900 !important;
  color: #253f44;
}

/* Syringe style */
.syringe-grid {
  margin-top: -15px !important;
}

.syringe-column {
  padding-bottom: 0 !important;
  margin-top: -20px !important;
}

.syringe-lines {
  position: relative;
  margin-top: -55px;
  margin-left: -27px;
}

.syringe-line {
  border-left: 2px solid #c3c3c3;
  display: inline-block;
  margin-right: 4px;

}

.short-syringe-line {
  height: 10px;
}

.long-syringe-line {
  height: 15px;
}

.syringe-container  {
  position: relative;
  float: right;
  -webkit-transform: translate(-75%, -50%);
          transform: translate(-75%, -50%);
}

.syringe {
  width: 23px;
  height: 100px;
  position: relative;
  background-color: #ddd;
  border: 2px solid #ddd;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.syringe:after,
.syringe:before {
  content: '';
  position: absolute;
  background-color: #ddd;
}

.syringe:after {
  width: 29px;
  height: 3px;
  top: -5px;
  left: -5px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.syringe:before {
  width: 10%;
  height: 20px;
  position: absolute;
  bottom: -22px;
  left: 45%;
}

.syringe .syringe-inside {
  width: 100%;
  height: 4px;
  background-color: #817d7c;
  position: absolute;
  border-radius: 5px;
}

.syringe .syringe-inside:after,
.syringe .syringe-inside:before {
  content: '';
  position: absolute;
  background-color: #817d7c;
}

.syringe .syringe-inside:after {
  width: 20%;
  height: 95px;
  top: -92px;
  left: 40%;
}

.syringe .syringe-inside:before {
  width: 25px;
  height: 5px;
  top: -96px;
  left: -2px;
  border-radius: 5px;
}

.syringe .blood {
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
  position: absolute;
  bottom: 0px;
}

.syringe .syringe-indicator {
  position: absolute;
  font-size: 0.8rem !important;
  top: -130px;
  left: -4px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg) !important;
}

.cancel-btn {
  height: 30px !important;
  width: 60px !important;
  font-size: 0.75rem !important;
  font-weight: bold !important;
  color: #17323b !important;
  position: absolute;
  right: -74%;
}

.marginTop {
  margin-top: 15px !important;
}

.syringe-label-side {
  position: fixed;
  right: 135px;
  bottom: 20px;
  color: #17323b !important;
}

.syringe-times {
  margin-top: -1.5% !important;
}

@-webkit-keyframes move-syringe-inside {
  0% {
    bottom: 90%;
  }
  100% {
    bottom: 0%;
  }
}

@keyframes move-syringe-inside {
  0% {
    bottom: 90%;
  }
  100% {
    bottom: 0%;
  }
}

@-webkit-keyframes move-blood {
  0% {
    height: 90%;
  }
  100% {
    height: 0%;
  }
}

@keyframes move-blood {
  0% {
    height: 90%;
  }
  100% {
    height: 0%;
  }
}
/* End of Syringe style */
.tooltip-modal, .tooltip-modal::before {
    background: khaki !important;
    font-size: 0.8rem !important;
}
/* CHANGED UI tab */
.ui.attached.tabular.menu {
  height: 2em;
  min-height: 0;
}

.ui.tabular.menu .active.item {
  padding: 0.5em 0.4em !important;
}
/* CHANGED UI tab */

.imaging-field .input input {
  width: 100% !important;
}

.injector-input{
  padding: 5px 5px 0 !important;
}

.injector-input input {
  height: 75% !important;
}

.thickness .actions {
  border: none !important;
}

.thickness-params-btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 116px !important;
}

.thickness-params-btns .scan-param-item{
  width: 17%;
}

.th-parameter-ctrl {
  width: 18% !important;
  margin: 5px !important;
  height: 3.5rem !important;  
  text-align: center;
  padding: 0 !important;
}

.padding-top {
  margin-top: 6px !important;
}

.imaging-form .fields {
  margin-bottom: 2px !important;
}

.custom-image-input {
  margin: auto;
  display: flex;
  align-items: center;
}

.custom-image-input label {
  text-align: right;
  font-size: 0.8rem !important;
  margin: 0 !important;  
  font-weight: 100 !important;   
  line-height: 9px;     
}

.custom-image-input .input {
  width: 100% !important;
  /* margin-left: 5px !important; */
}

.custom-image-input .dropdown {
  width: 100% !important;
  font-size: 0.8em !important;
  background: linear-gradient(to top, #cccccc 1%, #ffffff 100%) !important;
  font-weight: 900 !important;
}

.custom-image-input .dropdown .text {
  font-size: 0.75rem !important;
  font-weight: bold !important;
  color: #17323b !important;
}

.custom-image-input .icon {
  top: 0 !important;
  font-size: 1em !important;
  margin: auto !important;
}

#injector .header {
  margin-bottom: 3px !important;
  margin-left: 5px !important;
}

#injector table {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  margin-left: 3px !important;
  width: 99% !important;
}

#injector th, #injector .t-title  {
  line-height: 11px;  
  font-size: 0.8rem;
  padding: 4px 0 !important;
}

#injector .input, #injector .dropdown {
  height: 2.2rem !important;
  font-size: 0.8em !important;
}

.contrast-oral input{
  height: 3.5rem !important;
}

.scan-delay label, .peak-psi label {
  color: white;
  font-size: 0.8rem;
  margin: 0 !important;
}

.scan-delay input, .peak-psi input {
  height: 85%;
  text-align: center !important;
}

.text-align-center input{
  display: flex;
  justify-content: center !important; 
  text-align: center !important;
}

.text-align-right input{
  display: flex;
  justify-content: right !important; 
  text-align: right !important;
}


.contrast-oral-item {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.contrast-oral-items {
  display: flex;
  flex-direction: row;
  padding: 0 0 0 3px;
  margin-bottom: 2%;
}

.contrast-oral-item.margin-top, .contrast-oral-item.margin-top  {
  margin-top: 5% !important;
}

.contrast-oral-item .contrast-oral-btn, .contrast-oral-items .contrast-oral-btn {
  padding: 1rem 0.5em !important;
  width: 40% !important;
  height: 3.5rem !important;
}

.contrast-oral-item .contrast-oral-data, .contrast-oral-items .contrast-oral-data {
  min-width: 40%;
  margin-left: 3%;
  background: white;
  display: flex;
  height: 49px;
  width: 50px;
  overflow: auto;
  font-size: 10px;
  line-height: 11px;
  text-align: center;
}

.contrast-oral-data span{
  margin: auto;
}

#injector input {
  cursor: pointer;
}

.menu .item {
  font-size: 0.85rem !important;
  font-weight: 700 !important;
}

.ui.tab {
  padding: 0 !important;
}

/* ThicknessParameters */
.change-tk-values {
  display: flex;
  justify-content: flex-end;
  padding: 6px 0 12px 0!important;
}

.scan-param-modal.thickness {
  width: 40% !important;
}

.ctrls-label{
  font-weight: 700;
  margin: 5px !important;
}

.tk-ctrls {
  display: flex !important;
  flex-wrap: wrap;
  padding: 3px 0 0 3% !important;
}

.tk-ctrl-btn {
  flex: 0 0 20.8%;
  margin: 3px !important;
}

.tk-ctrl-btn.active {
  background: #76eafc !important;
}
/* ThicknessParameters */

.imaging-form .row {
  padding: 6px 0 0 0px !important;
}

.imaging-form .ui.grid {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}

.imaging-form .column {
  font-size: 0.8rem !important;
  line-height: 1rem !important;
  padding: 0 !important;
}

.imaging-form .column.label {
  font-size: 0.7rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end;
  margin-right: 5px !important;
  text-align: right;
}

.imaging-form .input input {
  padding: 5px !important;
}

.imaging-form .column .dropdown {
  min-width: 0em !important;
}

.imaging-form .ui.dropdown>.dropdown.icon {
  font-size: 1.5em !important;
}

.imaging-form .column .custom-image-input .icon {
  width: 0 !important;
  right: 0px !important;
  padding-top: 5px !important;
  padding-bottom: 0px !important;
}

.imaging-form .grid {
  margin-top: 0 !important;
}

.imaging-form .contrast-oral-item {
  margin-top: 7px !important;
}

.imaging-form .select-min {
  padding: 2px !important;
  min-height: 0 !important;
}

.imaging-form  .select-min .dropdown.icon {
  top: 0 !important;
  margin: auto !important;
}

.ui.tab.active, .ui.tab.open {
  padding-top: 5px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
}

.ui.tab.active.injector-tab, .ui.tab.open.injector-tab {
  padding-left: unset !important;
  padding-right: unset !important;
  padding-bottom: unset !important;
  padding-top: 7px !important;
}

.injector-tab thead th {
  padding: 2px !important;
  color: white !important;
  background: #798F9A !important;
  height: 31px !important;
}

.ui.tab.active.injector-tab .grid {
  margin: auto;
}

.ui.tab.active.injector-tab .grid .column {
  padding-bottom: 2px !important; 
  padding-top: 0 !important; 
  padding-left: 0.4rem !important; 
}

.contrast-oral-item {
  border-top: 1px solid white;
}

.scan-param-modal.thickness.one-val-change, 
.scan-param-modal-th.thickness.one-val-change {
  margin: auto !important;
  width: 350px !important;  
  background: #7c868c !important;
}

.scan-param-modal.thickness.one-val-change .header, 
.scan-param-modal-th.thickness.one-val-change .header {
  border: none !important;
}

.scan-param-modal.thickness.one-val-change .icon, 
.scan-param-modal-th.thickness.one-val-change .icon {
  padding: 5px !important;
}

.scan-param-modal.thickness.one-val-change .content, 
.scan-param-modal-th.thickness.one-val-change .content {
  width: 95% !important;
  margin: auto !important;
  padding: 0 !important;
}

.scan-param-modal.thickness.one-val-change .content .container, 
.scan-param-modal-th.thickness.one-val-change .content .container {
  width: 100% !important;
}

.scan-param-modal.thickness.one-val-change .footer, 
.scan-param-modal-th.thickness.thickness.one-val-change .footer {
  border: none !important;
}

.scan-param-modal.thickness.one-val-change button {
  height: 20px !important;
  width: 70px !important;
  padding: 1px 0px !important;  
  font-weight: 900 !important;
  margin-right: 4%;
}

.scan-param-modal-th.thickness.one-val-change button {
  height: 20px !important;
  width: 57px !important;
  padding: 1px 0px !important;  
  font-weight: 900 !important;
  font-size: 0.8rem !important;
}

.modal-label {
  font-weight: 900 !important;
  font-size: 0.8em !important;
  margin-left: 10px !important;
}

.modal-params-ctrls {
  display: flex !important;
  justify-content: flex-end !important;
  padding-bottom: 10px !important;
  padding-right: 5% !important;
}

.modal-params-ctrls .button {
  width: 18.1% !important;
}

.thickness.scan-param-modal-th.one-val-change.slice-gap {
  width: 280px !important;
}
.thickness.one-val-change.slice-gap .content .modal-label {
  display: none;
}

.thickness.one-val-change.slice-gap .content .tk-ctrls {
  padding-top: 10px !important; 
}

.ui.page.dimmer { 
  background-color: rgba(255, 255, 255, 0.4) !important; 
}

.scan-param-modal.thickness .ui.container, 
.scan-param-modal-th.thickness .ui.container {
  width: 95% !important;
}

.min-btn.reconstruction {
  width: 58% !important;
}

.min-btn.tk-btn {
  padding: 5px 15px !important;
}

.injector-modal-param .modal-params-ctrls .button {
  width: auto !important;
}

.padding-bottom {
  padding-bottom: 17px;
}
.thickness.modal.mini .header {
  font-size: 0.9rem !important;
}

.scan-tabs-section .segment {
  height: 224.78px !important;
}
#protocols .segment {
  font-weight: 700;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;  
  border: none !important;
  font-size: 0.9em !important;
  background: none !important;
  box-shadow: none !important;
  padding-top: 5px !important;
  padding-left: 5px !important;
  border-radius: none !important;  
}

#protocols .pointer {
  width: 90%; 
  cursor: pointer;
  padding: 0 5px;
  font-size: 0.7em;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.head-button {
  position: absolute;
  top: 2.5%;
}

.neck-button {
  position: absolute;
  top: 11.5%;
}

.chest-button {
  position: absolute;
  top: 19.5%;
}

.upper-extremity-button {
  position: absolute;
  top: 27%;
}

.abdomen-button {
  position: absolute;
  top: 34.5%;
}

.pelvis-button {
  position: absolute;
  top: 45%;
}

.lower-extremity-button {
  position: absolute;
  top: 68%;
}

#protocols .pointer:hover {
  background: #a9d9f9 !important;
}

#protocols .human-body-image {
  margin-left: 5px !important;
  height: 250px !important;
}

#protocols .image-section {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

#protocols .ptcls-section {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* MODAL */
.protocol-modal {
  width: 410px !important;
  height: 480px !important;
  background: lightgray !important;
  position: relative !important;
}

.protocol-modal .header, .protocol-modal .actions {
  background: lightgray !important;
  padding: 0.5rem 1.5rem !important;
  font-size: 0.8em !important;
  border: none !important;
  font-weight: 900;
}

.gray-row {
  background: #F7F7F7;
}

.modal-params-ctrls.custom-btns .button {
  background: #F7F7F7 !important;
  border: 1px solid gray !important;
}

.active-row {
  background: #9adafb !important;
}

.table-content {
  height: 93% !important;
  overflow: auto;
}

.ui.table thead tr:first-child > th {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
  z-index: 2;
}

.protocol-modal .modal-params-ctrls {
  display: flex !important;
  justify-content: flex-end !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
  bottom: 3%;
  right: 1%;
}

.protocol-modal .modal-params-ctrls .button {
  width: auto !important;
  padding: 6px 12px !important;
  font-size: 0.8rem !important;
}

.protocol-modal .modal-params-ctrls .button:first-child {
  margin-right: 15px !important;
}

tr {
  cursor: pointer;
}

td {
  height: 23px !important;
}

.protocol-modal table tr:hover td {
  background-color: #8bd3f988 !important;
  opacity: 1 !important;
}

.protocol-modal .icon {
  padding: 9px !important;
}

.protocol-modal .content {
  height: 93.5% !important;
  width: 95% !important;
  margin: auto !important;
  background: white !important;  
  padding: 1.5rem 1rem !important;
}

.protocol-modal th {
  padding: 5px !important;
  font-size: 0.8em !important;
}

.protocol-modal td {
  padding: 1px !important;
  font-size: 0.8em !important;
}

.modal-label-ptcl {
  display: flex;
  font-size: 0.7em !important;
  justify-content: flex-end;
  margin-top: -13px !important;
  margin-bottom: 5px;
}
.logoImage {
    width: 50%;
    margin: auto;
}

.nav-bar-images {
    width: 85%;
    margin: 10px auto;
}

.navBar-componetn .ui.button {
    padding: 5px 16px !important;
}

.selected-protocol {
    margin-bottom: 5px !important;
}

.selected-protocol label {
    font-weight: 100 !important;
}

.nav-ctrls {
    display: flex;
    justify-content: space-evenly;
}
.notification-modal {
    width: -webkit-max-content !important;
    width: max-content !important;
    background: #93a4ab !important;
    min-width: 120px !important;
}

.notification-modal .header {
    padding: 0.8rem !important;
    border: none !important;
    background: #93a4ab !important;
}

.notification-modal .content {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0.5rem 0.1rem !important;
    width: 94% !important;
    margin: auto;
    background: lightgray !important;
    font-size: 0.75rem !important;
    min-width: 110px !important;
}

.notification-modal .actions {
    padding: 0.5rem !important;
    border: none !important;
    background: #93a4ab !important;
}

.notification-modal .content .button {
    height: 20px !important;
    width: 70px !important;
    min-height: 0 !important;
    padding: 4px;
    font-size: 0.7rem;
    font-weight: 900;
    border: 2px solid gray !important;
    margin-top: 14px !important;
}

.notification-modal .content-action {
    display: flex;
    justify-content: center;
}
.admin-modal {
    height: 155px !important;
    width: 385px !important;
    background: lightgray !important;
}

.admin-modal .header {
    height: 30px !important;
    padding: 8px !important;
    border: none !important;
    font-size: 0.85rem !important;
    color: #17323b !important;
    font-weight: bold !important;
    background: lightgray !important;
}

.admin-modal .content {
    background: white !important;
    width: 94% !important;
    height: 74% !important;
    margin: auto !important;
}

.admin-modal .admin-label {
    margin: 0 !important;
    text-align: center;
    font-size: 0.72rem !important;
}

.admin-modal .admin-input-field {
    display: flex !important;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    margin-top: 10px !important;
    margin-bottom: 10px !important;

}

.admin-modal .admin-input-field span {
    font-size: 0.72rem !important;
}

.admin-modal .admin-input-field .input {
    display: inline-block !important;
    width: 250px !important;
}

.admin-modal .ui.fluid.input>input {
    width: 250px !important;
}

.admin-modal .actions {
    background: lightgray !important;
    border: none !important;
    padding: 5px !important;
}

.admin-modal .login-btn {
    height: 20px !important;
    width: 55px !important;
    padding: 1px !important;
    font-size: 0.72rem !important;
    font-weight: bold !important;
    float: right;
}

.admin-modal .error {
    color: red;
    font-size: 0.72rem !important;
}
#scan_params .header {
  margin: 1% !important;
}

.parameter-card-content {
  height: 3.3rem;
  text-align: center;
  padding: 0 2px !important;
  font-size: 0.75em !important;
}

.scan-params-ctrls {
  display: flex;
}

.parameter-ctrl {
  width: 10%;
  margin: 4px !important;
  height: 3.5rem !important;  
  text-align: center;
  padding: 0 !important;
  max-height: 600px;
}

.scan-param {
  width: 100%;
  height: 3.5rem;
  display: flex;
  margin-top: 10px;  
  text-align: center;
  align-items: center;  
  background: white;
  justify-content: center;
  font-size: 0.7rem !important;
}

.scan-params-btns {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.9rem;
  flex-direction: row;
  max-height: 115px;
}

.scan-param-item {
  width: 10%;
  margin: 5px;
}

.new-ptcl {
  margin-left: 8% !important;
}

.prev-series {
  margin-left: 3% !important;
}

/* MODAL */

.modal.mini.scan-param-modal {
  width: 20.5% !important;
}

.modal.mini.scan-param-modal-th{
  width: 350px !important;
}

.scan-param-modal .header, .scan-param-modal .actions,
.scan-param-modal-th .header, .scan-param-modal-th .actions{
  background: #8294A6 !important;
}

.scan-param-modal .header,
.scan-param-modal-th .header {
  padding: 0.25rem 1.5rem !important;
}

.scan-param-modal .actions,
.scan-param-modal-th .actions {
  padding: 0.5rem 1.5rem !important;
}

.scan-param-modal .content,
.scan-param-modal-th .content {
  background: #96A8B7 !important;
}

.modal.mini.scan-param-modal,
.modal.mini.scan-param-modal-th {
  background: #8294A6 !important;
}

.modal.mini.scan-param-modal .content,
.modal.mini.scan-param-modal-th .content {
  padding-bottom: 4px !important;
  width: 95% !important;
  margin: auto !important;
}

.modal.mini.scan-param-modal .content .dropdown,
.modal.mini.scan-param-modal-th .content .dropdown {
  min-height: 25px !important;
  padding: 2% !important;
}

.modal.mini.scan-param-modal .content .dropdown .icon,
.modal.mini.scan-param-modal-th .content .dropdown .icon{
  padding-right: 15px !important;
}

.modal.mini.scan-param-modal .modal-params-ctrls, 
.modal.mini.scan-param-modal-th .modal-params-ctrls{
  margin-top: 5px !important;
  padding-bottom: 3px !important;
}

.modal.mini.scan-param-modal .icon, .modal.mini.scan-param-modal-th .icon {
  padding: 5px !important;
  width: 20px;
}

.modal.mini.scan-param-modal .header, .modal.mini.scan-param-modal .actions {
  border: none !important;
}

.scanParam-modal-param .modal-params-ctrls {
  padding-right: 0 !important;
}

.scanParam-modal-param .modal-params-ctrls .button {
  width: 60px !important;
  height: 22px !important;
  font-size: 0.75rem !important;
}
.custom-form-input {
    margin: auto;
    display: flex;
    align-items: center;
    margin-bottom: 5px !important;
}

.custom-form-input label {
    width: 6rem;
    text-align: right;
    margin: 0 !important;  
    line-height: 10px !important;
    font-weight: 100 !important;          
}

.custom-form-input .input, .custom-form-input .dropdown {
    width: 90% !important;
    margin-left: 5px !important;
}

.custom-form-input .dropdown {
    padding: 2px !important;
    min-height: 0 !important;
}

.custom-form-input .dropdown.icon {
    top: 0 !important;
    margin: auto !important;
  }

.custom-form-input .icon {
    margin: 0 !important;
    padding: 0 !important;
    width: 10px !important;
    margin-top: -2px !important;
}

.patient-position-image {
    margin: auto !important;
    height: 135px;
    margin-bottom: 0.5rem !important;
}
.guide {
    background: khaki;
    font-size: 0.8rem;
    position: absolute;
    padding: 0 7px;
    border-radius: 5px;
}

#guide-step1 {    
    top: 7%;
    left: -20%;
}

#guide-step2 {
    top: 16%;
    left: -25%;
}

#guide-step3 {
    top: 64%;
    left: 60.5%;
}
.ui.container.home-page {
    width: 944px !important;
}

body {
    overflow: hidden !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.home-page .ui.centered.grid {
    width: 944px !important;
    margin: 0 !important;
}

.navBar-section {
    display: flex !important;
    justify-content: flex-end;
    width: 236px !important;
}

.scan-params-section .ui.centered.grid>.row>.column:not(.aligned):not(.justified) {
    width: 48.2% !important;
}

.scan-params-section, .scanTiming-section {
    padding: 2px !important;
}

.scanTiming-section {
    border: 1px solid white;
    padding-top: 5px !important; 
    width: 943px !important;
}

.scan-params-section, .ptInfo-section {
    border-left: 1px solid white;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}

.ptInfo-section {
    width: 236px !important;
}

.ptProtocol-section {
    border-right: 1px solid white;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 472px !important;
}

.scan-tabs-section {
    padding: 2px 0 0 0 !important;
    border-right: 1px solid white;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 26.8% !important;
}

.autoView-segment {
    padding: 1rem 0 0 0 !important;
}


#first-row {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    width: 944px !important;
    height: 289px !important;
}

#second-row {
    padding: 0 !important;
    width: 944px !important;
    height: 269px !important;
}

#third-row {
    padding-top: 0 !important;
    width: 944px !important;
    height: 90px !important;
    padding-bottom: 0 !important;
}

#patient-col {
    width: 221px !important;
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 0;
}

#stackable-section {
    height: 100%;
    margin: 0;
    margin-top: -15px; 
}

#home-autoview {
    width: 222px !important;
}

#scan-parameters {
    width: 606px !important;
}

#scan-tabs {
    width: 338px !important;
}
.rec .close.icon {
    padding: 1% !important;
}

.rec .header {
    background: #8294A6 !important;
    border: none !important;
    padding: 1% .5% !important;
    font-weight: 700;
    color: white !important;
}

.rec .actions {
    background: #8294A6 !important;
    border: none !important;
    padding: 5px !important;
}
.rec.modal.mini {
    width: auto !important;
    padding: 0 8px !important;
    background: #8294A6 !important;
}

.rec .content{
    height: 330px !important;
    padding: 0px !important;
    width: 630px !important;
    margin: auto !important;
    background: #96A8B7 !important;
}

.rec .content .column, .rec .content .row { 
    height: 100% !important;
    width: 100% !important;
    margin: auto !important; 
    padding: 0 !important;
}

.rec .content .row {     
    padding: 5px !important;
}

.rec .rec-images {
    height: 97% !important;
}

.rec .rec-images img {
    width: 100%;
    height: 244px;
}

.rec .autoview-container .carousel__slider {
    height: auto !important;
}

.rec-autoview-label {
    margin: 2% auto;
    font-size: 0.8em;
    font-weight: 900;
    color: white;
}

.rec-actions-section {
    padding: 2% 8% 0 0% !important;
}

.rec .select-padding-ctrl {
    padding: 6px 15px !important;
}

.rec .plan-slices {
    width: auto !important;
}

.rec .plan-slices button {
    width: 97% !important;
}

.dd-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 3% !important;
}

.dd-block-label {
    font-size: 0.65em !important;
    font-weight: 700 !important;
    margin: auto !important;
    margin-right: 9px !important;
    width: 30% !important;
    text-align: end !important;
}

.action-block-label {
    font-size: 0.65em !important;
    font-weight: 700 !important;
    margin: auto 0 !important;
    margin-right: 9px !important;
}

.dd-block .dropdown {
    height: 20px !important;
    padding: 1% !important;
    min-height: 0 !important;
    width: 65% !important;
    font-size: 0.8em !important;
    background: linear-gradient(to top, #cccccc 1%, #ffffff 100%) !important;
    font-weight: bold !important;
    color: #17323b !important;
}

.dd-block .ui.dropdown .menu .item.active {
    background-color: skyblue !important;
}

.dd-block .ui.dropdown .menu .item:hover {
    background-color: #b2e1ff !important;
}

.dd-block .dropdown.icon{
    width: 12% !important;
    height: 17px !important;
    font-size: 0.9rem !important;
}

.dd-block .ui.selection.dropdown .menu>.item {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
    border: none;
}

.sec-inputs .input {
    height: 20px;
    width: 40%;
    margin-right: 5px !important;
    font-size: 0.8em !important;
}

.sec-inputs-element {
    margin-top: 7px !important;
    display: flex !important;
    justify-content: flex-end !important;
}

.modal.mini .rec-actions-section .button {
    padding: 1% 7% !important;
    right: 16% !important;
    position: absolute !important;
    margin-top: 2% !important;
    font-size: 0.8em;
    color: #0B333C;
}

.rec .carousel {
    height: 100% !important;
}

.rec-autoview .plan-slices-dashboard {
    height: 75% !important;
}

#reconstruction {
    height: 100%;
    width: 100%;
}
.field-error {
  color: #9f3a38;
  float: left;
  margin-top: -13px;
  margin-bottom: 20px;
}
.form-grid {
  padding-top: 2% !important;
  overflow-y: scroll;
  height: 100vh;
  margin-right: 0 !important;
}

.form-grid-column {
  max-width: 450px;
}

.sign-up-link {
  margin-bottom: 0 !important;
  margin-top: 2px !important;
}

.restore-link {
  margin-top: 2px !important;
}
.restore-page {
    /* padding-top: 10rem; */
}

.restore-block {
    width: 400px;
    margin: 0 auto;
    text-align: center;
    background: white;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.restore-block > h3 {
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

.restore-block > p {
    color: red;
    font-size: 12px;
    padding-top: 5px;
}

.input-restore {
    width: 300px;
    height: 40px !important;
    font-size: 14px !important;
}

.restore-submit { 
    display: block !important;
    width: 100px;
    margin: 14px auto 0 auto !important;
    color: white !important;
}

.restore-page .message {
    width: 400px !important;
    margin: 5px auto 0 auto !important;
    padding: 5px 10px !important;
    font-size: 12px !important;
}

.restore-page .message .header {
    margin-bottom: 10px !important;
}
.new-password-page {
    /* padding-top: 10rem; */
}

.new-password-block {
    width: 400px;
    margin: 0 auto;
    text-align: center;
    background: white;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.new-password-block > div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.password-flex-item-1 {
    width: 100px;
    text-align: left;
    font-size: 0.9rem;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

.new-password-block input {
    display: block;
    width: 250px;
    margin-bottom: 14px !important;
    height: 40px;
    font-size: 12px;
}

.new-password-block > button {
    width: 100px;
}

.new-password-block p {
    color: red;
    font-size: 12px;
}

.restore-submit { 
    display: block !important;
    width: 100px;
    margin: 14px auto 0 auto !important;
}

.new-password-page .message {
    width: 400px !important;
    margin: 5px auto 0 auto !important;
    padding: 5px 10px !important;
}

.new-password-page .message .header {
    margin-bottom: 10px !important;
}
body {
  background: #93a4ab !important;
  font-size: 1rem !important;
}

h5 {
  font-size: 0.85rem !important;
}

h4, h5 {
  margin-top: 0 !important;
  margin-bottom: 10px !important;
}

h6 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

h3.header, h4.header, h5.header {
  color: white !important;
}

label {
  text-align: left;
  display: block;
  margin: 12px 0 .28571429rem 0;
  font-size: .92857143em;
  font-weight: 700;
  text-transform: none;
}

.ui.modal .close.icon {
  font-size: 0.9rem !important;
  top: 0 !important;
  right: 0 !important;
  color: #000000 !important;
}

.segment.tab, .tab table {
  background-color: #8e9faa !important;
}

.ui.container {
  width: 90% !important;
}

.wide.column.top-position {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

button.min-btn {
  padding: 4px !important;
  font-size: 0.75rem !important;
}

.home-page input, .modal input {
  padding: 2px !important;
}

.register-form 

/* SECTION padding */
.ui.celled.grid>.column:not(.row), .ui.celled.grid>.row>.column {
  padding: 0.5em !important;
}
/* SECTION padding */

/* MODAL */
.modal.mini {
  width: 30% !important;
}

.modal.mini .header {
  font-size: .8rem !important;
  color: #17323b !important;
}

.modal.mini .content {
  padding-left: 2%;
  padding-right: 2%;
}

.modal.mini .button {
  padding: 1px 10px !important;
}
/* MODAL */

.home-page .ui.dropdown>.dropdown.icon {
  right: 5px !important;
  font-size: 1.2em !important;
  width: auto !important;
}

.home-page .ui.dropdown .menu .item.active {
  background-color: skyblue !important;
}

.home-page .ui.dropdown .menu .item {
  border: none !important;
  font-weight: 400 !important;
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.home-page .ui.dropdown .menu .item:hover {
  background-color: #b2e1ff !important;
}

.home-page .ui.fluid.dropdown {
  background: linear-gradient(to top, #cccccc 1%, #ffffff 100%) !important;
  font-weight: bold !important;
  color: #17323b !important;
}

.ui.selection.visible.dropdown>.text:not(.default) {
  font-weight: bold !important;
}

.ui.tabular.menu .active.item {
  background: #93a4ab !important;
}

.ui.tabular.menu .item {
  border-right: 1px solid lightgrey;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background: rgba(254,254,254,1);
  background: rgba(254,254,254,1);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(254,254,254,1)), color-stop(0%, rgba(226,226,226,1)), color-stop(100%, rgba(142,154,160,1)));
  background: linear-gradient(to bottom, rgba(254,254,254,1) 0%, rgba(226,226,226,1) 0%, rgba(142,154,160,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#8e9aa0', GradientType=0 );
}

.column .top-position {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

/* STOP DRAGGING IMAGE */
.carousel img {
  pointer-events: none;
}

button {
  color: #0B333C !important;
  font-weight: 900 !important;
}

.blurring.dimmed.dimmable>:not(.dimmer) {
  filter: blur(1px) !important;
  -webkit-filter: blur(1px) !important;
}

.carousel .ui.button:focus {
  background-color: #e0e1e2 !important;
}
.carousel .ui.button:hover {
  background-color: white !important;
}

.carousel__slider {
  background: #000;
}

/* input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}

input[type=number]::-webkit-outer-spin-button, 
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button !important;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
} */
