.autoview-container {
  height: 100% !important;
  /* width: 230px !important; */
}

.full-height {
  display: inline;
}

.empty-image {
  width: 100%;
  height: 215px;
  background-color: black;
  padding-bottom: 10px;
}
.carousel-image {
  width: 100%;
  height: 215px;
}
.carousel-image img {
  width: 100%;
  height: 100%;
}

button.btn-prev,
button.btn-next {
  font-size: 1.2em !important;
  padding: 0 !important;
  width: 10% !important;
}

.autoview-ctrls {
  display: flex;
  margin-top: 8px !important;
  justify-content: center;
  height: 8%;
}

.scan-series-select {
  display: flex !important;
  align-items: left;
  padding: 5px 5px !important;
  min-height: 1em !important;
  min-width: 20% !important;
}

.scan-series-select .icon {
  top: 0 !important;
  right: 0 !important;
  padding-top: 35% !important;
}

.search-bth {
  padding: 2px !important;
  /* margin-top: 5px !important; */
  position: absolute !important;
  right: 0 !important;
  top: 6% !important;
}

.auto-view-modal .content {
  padding: 0.5rem !important;
}

.auto-view-modal .autoview-ctrls {
  height: 26px;
  display: flex !important;
  justify-content: center !important;
}

.auto-view-modal button.btn-prev,
.auto-view-modal button.btn-next {
  width: 5% !important;
}

.auto-view-modal .scan-series-select .icon {
  padding-top: 12% !important;
}

.black-image {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 210;
}

.autoview-container .carousel__slider {
  height: auto !important;
}

.carousel {
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.auto-view-modal {
  background-color: lightgray !important;
}

.auto-view-modal .header {
  padding: 0.5rem 1rem !important;
  font-size: 0.8rem !important;
  border: none !important;
  background-color: lightgray !important;
}

.auto-view-modal .carousel,
.auto-view-modal .carousel .carousel__slider,
.auto-view-modal .carousel .carousel__slide {
  height: -webkit-fill-available;
  height: 100%;
}

.auto-view-modal .content {
  width: 98% !important;
  margin: auto !important;
  height: 94.5% !important;
  padding-bottom: 36px !important;
  background-color: white !important;
}

.auto-view-modal img {
  width: 65% !important;
  margin: auto !important;
}

.auto-view-modal .dashboard {
  position: absolute;
  width: 100%;
  height: 86%;
  margin: auto;
  top: 6%;
}

.auto-view-modal .ui.selection.dropdown {
  background: linear-gradient(to top, #cccccc 1%, #ffffff 100%) !important;
  font-weight: bold !important;
  color: #17323b !important;
}

.auto-view-modal .ui.selection.dropdown .menu > .item {
  border: none;
}

.auto-view-modal .ui.dropdown .menu .item.active {
  background-color: skyblue !important;
}

.auto-view-modal .ui.dropdown .menu .item:hover {
  background-color: #b2e1ff !important;
}

.auto-view-modal .scan-series-select {
  display: flex !important;
  align-items: center;
  padding: 5px !important;
  min-height: 1em !important;
  min-width: 50px !important;
}

.auto-view-modal button.btn-prev,
.auto-view-modal button.btn-next {
  width: 26px !important;
}

.auto-view-modal .scan-series-select .icon {
  padding-top: 16px !important;
}

#autoview-modal {
  height: 600px;
  width: 900px;
  padding-bottom: 10px !important;
}
