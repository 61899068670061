.rec .close.icon {
    padding: 1% !important;
}

.rec .header {
    background: #8294A6 !important;
    border: none !important;
    padding: 1% .5% !important;
    font-weight: 700;
    color: white !important;
}

.rec .actions {
    background: #8294A6 !important;
    border: none !important;
    padding: 5px !important;
}
.rec.modal.mini {
    width: auto !important;
    padding: 0 8px !important;
    background: #8294A6 !important;
}

.rec .content{
    height: 330px !important;
    padding: 0px !important;
    width: 630px !important;
    margin: auto !important;
    background: #96A8B7 !important;
}

.rec .content .column, .rec .content .row { 
    height: 100% !important;
    width: 100% !important;
    margin: auto !important; 
    padding: 0 !important;
}

.rec .content .row {     
    padding: 5px !important;
}

.rec .rec-images {
    height: 97% !important;
}

.rec .rec-images img {
    width: 100%;
    height: 244px;
}

.rec .autoview-container .carousel__slider {
    height: auto !important;
}

.rec-autoview-label {
    margin: 2% auto;
    font-size: 0.8em;
    font-weight: 900;
    color: white;
}

.rec-actions-section {
    padding: 2% 8% 0 0% !important;
}

.rec .select-padding-ctrl {
    padding: 6px 15px !important;
}

.rec .plan-slices {
    width: auto !important;
}

.rec .plan-slices button {
    width: 97% !important;
}

.dd-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 3% !important;
}

.dd-block-label {
    font-size: 0.65em !important;
    font-weight: 700 !important;
    margin: auto !important;
    margin-right: 9px !important;
    width: 30% !important;
    text-align: end !important;
}

.action-block-label {
    font-size: 0.65em !important;
    font-weight: 700 !important;
    margin: auto 0 !important;
    margin-right: 9px !important;
}

.dd-block .dropdown {
    height: 20px !important;
    padding: 1% !important;
    min-height: 0 !important;
    width: 65% !important;
    font-size: 0.8em !important;
    background: linear-gradient(to top, #cccccc 1%, #ffffff 100%) !important;
    font-weight: bold !important;
    color: #17323b !important;
}

.dd-block .ui.dropdown .menu .item.active {
    background-color: skyblue !important;
}

.dd-block .ui.dropdown .menu .item:hover {
    background-color: #b2e1ff !important;
}

.dd-block .dropdown.icon{
    width: 12% !important;
    height: 17px !important;
    font-size: 0.9rem !important;
}

.dd-block .ui.selection.dropdown .menu>.item {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
    border: none;
}

.sec-inputs .input {
    height: 20px;
    width: 40%;
    margin-right: 5px !important;
    font-size: 0.8em !important;
}

.sec-inputs-element {
    margin-top: 7px !important;
    display: flex !important;
    justify-content: flex-end !important;
}

.modal.mini .rec-actions-section .button {
    padding: 1% 7% !important;
    right: 16% !important;
    position: absolute !important;
    margin-top: 2% !important;
    font-size: 0.8em;
    color: #0B333C;
}

.rec .carousel {
    height: 100% !important;
}

.rec-autoview .plan-slices-dashboard {
    height: 75% !important;
}

#reconstruction {
    height: 100%;
    width: 100%;
}