#protocols .segment {
  font-weight: 700;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;  
  border: none !important;
  font-size: 0.9em !important;
  background: none !important;
  box-shadow: none !important;
  padding-top: 5px !important;
  padding-left: 5px !important;
  border-radius: none !important;  
}

#protocols .pointer {
  width: 90%; 
  cursor: pointer;
  padding: 0 5px;
  font-size: 0.7em;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.head-button {
  position: absolute;
  top: 2.5%;
}

.neck-button {
  position: absolute;
  top: 11.5%;
}

.chest-button {
  position: absolute;
  top: 19.5%;
}

.upper-extremity-button {
  position: absolute;
  top: 27%;
}

.abdomen-button {
  position: absolute;
  top: 34.5%;
}

.pelvis-button {
  position: absolute;
  top: 45%;
}

.lower-extremity-button {
  position: absolute;
  top: 68%;
}

#protocols .pointer:hover {
  background: #a9d9f9 !important;
}

#protocols .human-body-image {
  margin-left: 5px !important;
  height: 250px !important;
}

#protocols .image-section {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

#protocols .ptcls-section {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* MODAL */
.protocol-modal {
  width: 410px !important;
  height: 480px !important;
  background: lightgray !important;
  position: relative !important;
}

.protocol-modal .header, .protocol-modal .actions {
  background: lightgray !important;
  padding: 0.5rem 1.5rem !important;
  font-size: 0.8em !important;
  border: none !important;
  font-weight: 900;
}

.gray-row {
  background: #F7F7F7;
}

.modal-params-ctrls.custom-btns .button {
  background: #F7F7F7 !important;
  border: 1px solid gray !important;
}

.active-row {
  background: #9adafb !important;
}

.table-content {
  height: 93% !important;
  overflow: auto;
}

.ui.table thead tr:first-child > th {
  position: sticky !important;
  top: 0;
  z-index: 2;
}

.protocol-modal .modal-params-ctrls {
  display: flex !important;
  justify-content: flex-end !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
  bottom: 3%;
  right: 1%;
}

.protocol-modal .modal-params-ctrls .button {
  width: auto !important;
  padding: 6px 12px !important;
  font-size: 0.8rem !important;
}

.protocol-modal .modal-params-ctrls .button:first-child {
  margin-right: 15px !important;
}

tr {
  cursor: pointer;
}

td {
  height: 23px !important;
}

.protocol-modal table tr:hover td {
  background-color: #8bd3f988 !important;
  opacity: 1 !important;
}

.protocol-modal .icon {
  padding: 9px !important;
}

.protocol-modal .content {
  height: 93.5% !important;
  width: 95% !important;
  margin: auto !important;
  background: white !important;  
  padding: 1.5rem 1rem !important;
}

.protocol-modal th {
  padding: 5px !important;
  font-size: 0.8em !important;
}

.protocol-modal td {
  padding: 1px !important;
  font-size: 0.8em !important;
}

.modal-label-ptcl {
  display: flex;
  font-size: 0.7em !important;
  justify-content: flex-end;
  margin-top: -13px !important;
  margin-bottom: 5px;
}