.ui.container.home-page {
    width: 944px !important;
}

body {
    overflow: hidden !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.home-page .ui.centered.grid {
    width: 944px !important;
    margin: 0 !important;
}

.navBar-section {
    display: flex !important;
    justify-content: flex-end;
    width: 236px !important;
}

.scan-params-section .ui.centered.grid>.row>.column:not(.aligned):not(.justified) {
    width: 48.2% !important;
}

.scan-params-section, .scanTiming-section {
    padding: 2px !important;
}

.scanTiming-section {
    border: 1px solid white;
    padding-top: 5px !important; 
    width: 943px !important;
}

.scan-params-section, .ptInfo-section {
    border-left: 1px solid white;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}

.ptInfo-section {
    width: 236px !important;
}

.ptProtocol-section {
    border-right: 1px solid white;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 472px !important;
}

.scan-tabs-section {
    padding: 2px 0 0 0 !important;
    border-right: 1px solid white;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 26.8% !important;
}

.autoView-segment {
    padding: 1rem 0 0 0 !important;
}


#first-row {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    width: 944px !important;
    height: 289px !important;
}

#second-row {
    padding: 0 !important;
    width: 944px !important;
    height: 269px !important;
}

#third-row {
    padding-top: 0 !important;
    width: 944px !important;
    height: 90px !important;
    padding-bottom: 0 !important;
}

#patient-col {
    width: 221px !important;
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 0;
}

#stackable-section {
    height: 100%;
    margin: 0;
    margin-top: -15px; 
}

#home-autoview {
    width: 222px !important;
}

#scan-parameters {
    width: 606px !important;
}

#scan-tabs {
    width: 338px !important;
}