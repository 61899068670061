#canvas {
    width: 100%;
    height: 100%;
}

.plan-slices {
    display: contents;
    width: 60% !important;
}

.padding-ctrl {
    padding: 4px !important;
}

.select-padding-ctrl {
    padding: 5px 0px !important;
    font-size: 0.8em !important;
    width: 55%;
    height: 100%;
}

.plan-slices-dashboard {
    z-index: 2;
    height: auto !important;
    position: absolute;
    width: -webkit-fill-available;
    height: 71% !important;
}

#autoView {
    height: 100%;
    width: 100%;
}