.custom-form-input {
    margin: auto;
    display: flex;
    align-items: center;
    margin-bottom: 5px !important;
}

.custom-form-input label {
    width: 6rem;
    text-align: right;
    margin: 0 !important;  
    line-height: 10px !important;
    font-weight: 100 !important;          
}

.custom-form-input .input, .custom-form-input .dropdown {
    width: 90% !important;
    margin-left: 5px !important;
}

.custom-form-input .dropdown {
    padding: 2px !important;
    min-height: 0 !important;
}

.custom-form-input .dropdown.icon {
    top: 0 !important;
    margin: auto !important;
  }

.custom-form-input .icon {
    margin: 0 !important;
    padding: 0 !important;
    width: 10px !important;
    margin-top: -2px !important;
}

.patient-position-image {
    margin: auto !important;
    height: 135px;
    margin-bottom: 0.5rem !important;
}