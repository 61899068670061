.scan-progress {
  margin-top: 4px !important;
}

.scan-progress-container {
  margin-top: -25px;
  padding-top: 15px !important;
  padding-bottom: 10px !important;
}

.scan-progress-container-ctrl {
  display: flex !important;
  align-items: center;
  margin-top: -25px;
  padding-top: 15px !important;
}

.progress-label {
  font-size: 0.8rem !important;
  font-weight: 900 !important;
  color: #253f44;
}

/* Syringe style */
.syringe-grid {
  margin-top: -15px !important;
}

.syringe-column {
  padding-bottom: 0 !important;
  margin-top: -20px !important;
}

.syringe-lines {
  position: relative;
  margin-top: -55px;
  margin-left: -27px;
}

.syringe-line {
  border-left: 2px solid #c3c3c3;
  display: inline-block;
  margin-right: 4px;

}

.short-syringe-line {
  height: 10px;
}

.long-syringe-line {
  height: 15px;
}

.syringe-container  {
  position: relative;
  float: right;
  transform: translate(-75%, -50%);
}

.syringe {
  width: 23px;
  height: 100px;
  position: relative;
  background-color: #ddd;
  border: 2px solid #ddd;
  transform: rotate(-90deg);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.syringe:after,
.syringe:before {
  content: '';
  position: absolute;
  background-color: #ddd;
}

.syringe:after {
  width: 29px;
  height: 3px;
  top: -5px;
  left: -5px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.syringe:before {
  width: 10%;
  height: 20px;
  position: absolute;
  bottom: -22px;
  left: 45%;
}

.syringe .syringe-inside {
  width: 100%;
  height: 4px;
  background-color: #817d7c;
  position: absolute;
  border-radius: 5px;
}

.syringe .syringe-inside:after,
.syringe .syringe-inside:before {
  content: '';
  position: absolute;
  background-color: #817d7c;
}

.syringe .syringe-inside:after {
  width: 20%;
  height: 95px;
  top: -92px;
  left: 40%;
}

.syringe .syringe-inside:before {
  width: 25px;
  height: 5px;
  top: -96px;
  left: -2px;
  border-radius: 5px;
}

.syringe .blood {
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
  position: absolute;
  bottom: 0px;
}

.syringe .syringe-indicator {
  position: absolute;
  font-size: 0.8rem !important;
  top: -130px;
  left: -4px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg) !important;
}

.cancel-btn {
  height: 30px !important;
  width: 60px !important;
  font-size: 0.75rem !important;
  font-weight: bold !important;
  color: #17323b !important;
  position: absolute;
  right: -74%;
}

.marginTop {
  margin-top: 15px !important;
}

.syringe-label-side {
  position: fixed;
  right: 135px;
  bottom: 20px;
  color: #17323b !important;
}

.syringe-times {
  margin-top: -1.5% !important;
}

@-moz-keyframes move-syringe-inside {
  0% {
    bottom: 90%;
  }
  100% {
    bottom: 0%;
  }
}

@-webkit-keyframes move-syringe-inside {
  0% {
    bottom: 90%;
  }
  100% {
    bottom: 0%;
  }
}

@-o-keyframes move-syringe-inside {
  0% {
    bottom: 90%;
  }
  100% {
    bottom: 0%;
  }
}

@keyframes move-syringe-inside {
  0% {
    bottom: 90%;
  }
  100% {
    bottom: 0%;
  }
}

@-moz-keyframes move-blood {
  0% {
    height: 90%;
  }
  100% {
    height: 0%;
  }
}

@-webkit-keyframes move-blood {
  0% {
    height: 90%;
  }
  100% {
    height: 0%;
  }
}

@-o-keyframes move-blood {
  0% {
    height: 90%;
  }
  100% {
    height: 0%;
  }
}

@keyframes move-blood {
  0% {
    height: 90%;
  }
  100% {
    height: 0%;
  }
}
/* End of Syringe style */